import React, { PropsWithChildren } from 'react';
import { Button as DefaultButton, composeStyles, PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { Spinner } from '@components/atoms/Spinner/Spinner';
import { buttonProps } from './props/default';

export interface ButtonProps extends PropsWithChildren, PropsWithClassProps<'button'> {
  hasAction?: boolean;
  title?: string;
  id?: string;
  isLoading?: boolean;
  omit?: boolean;
  onAction?: () => void;
  link?: string;
  openInTab?: boolean;
  buttonStyle?: 'primary' | 'secondary' | 'tertiary' | 'viewLink';
  full?: boolean;
  PreElement?: React.ReactNode;
}

export const Button = ({
  title,
  id,
  hasAction,
  isLoading,
  link,
  onAction,
  classProps,
  openInTab,
  buttonStyle = 'secondary',
  omit,
  PreElement,
  children,
}: ButtonProps) => {
  const props = buttonProps[buttonStyle];

  return (
    <DefaultButton
      {...props}
      id={id}
      hasAction={hasAction}
      onAction={onAction}
      link={link}
      openInTab={openInTab}
      omit={omit}
      PreElement={isLoading ? <Spinner iconSize="1em" /> : PreElement || props.PreElement}
      classProps={composeStyles(props.classProps, classProps)}
    >
      {children || title}
    </DefaultButton>
  );
};
