import { ButtonProps } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';
import { IconPlayTvguide } from '@assets/icon-play-tvguide';

const baseStyle =
  'inline-flex min-h-[43px] cursor-pointer place-content-center rounded-full px-8 py-2.5 font-herokid text-2xs font-bold uppercase leading-body tracking-widest shadow-card-sm transition-colors duration-300 items-center gap-2';

export const buttonProps: { [key: string]: ButtonProps } = {
  primary: {
    classProps: {
      root: twMerge(baseStyle, 'border border-primary bg-transparent text-white hover:bg-primary'),
    },
  },
  secondary: {
    classProps: {
      root: twMerge(baseStyle, 'bg-gray-700 text-white backdrop-blur-sm hover:bg-white hover:text-gray-820'),
    },
  },
  tertiary: {
    classProps: {
      root: twMerge(baseStyle, 'bg-white/70 text-gray-820 hover:bg-white'),
    },
  },
  viewLink: {
    PreElement: <IconPlayTvguide />,
    classProps: {
      root: twMerge(baseStyle, 'border border-primary bg-transparent text-white hover:bg-primary'),
    },
  },
};
